import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, keywords, title }: any) {
	return (
		<StaticQuery
			query={detailsQuery}
			render={data => {
				const metaDescription = description || data.site.siteMetadata.description;
				const ttitle = title || data.site.siteMetadata.title;
				return (
					<Helmet
						htmlAttributes={{ lang }}
						title={ttitle}
						meta={[
							{
								name   : `description`,
								content: metaDescription,
							},
							{
								property: `og:title`,
								content : ttitle,
							},
							{
								property: `og:description`,
								content : metaDescription,
							},
							{
								property: `og:url`,
								content : 'https://dnsadblock.com',
							},
							{
								property: `og:image`,
								content : data.ogimage.childImageSharp.fluid.src,
							},
							{
								property: `og:type`,
								content : `website`,
							},
							{
								name   : `twitter:card`,
								content: `summary`,
							},
							{
								name   : `twitter:creator`,
								content: data.site.siteMetadata.author,
							},
							{
								name   : `twitter:title`,
								content: ttitle,
							},
							{
								name   : `twitter:description`,
								content: metaDescription,
							},
						]
							.concat(
								keywords.length > 0
									? {
										name   : `keywords`,
										content: keywords.join(`, `),
									}
									: [],
							)
							.concat(meta)}
					/>
				);
			}}
		/>
	);
}

SEO.defaultProps = {
	lang    : `en`,
	meta    : [],
	keywords: [],
};

SEO.propTypes = {
	description: PropTypes.string,
	lang       : PropTypes.string,
	meta       : PropTypes.array,
	keywords   : PropTypes.arrayOf(PropTypes.string),
	title      : PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site {
			siteMetadata {
			title
			description
			author
		}
	}
	
	ogimage: file(relativePath:{regex:"/ogimage.png/"}) {
		childImageSharp {
			fluid(maxWidth: 400, maxHeight: 250) {
				...GatsbyImageSharpFluid
			}
		}
	}
}
`;
