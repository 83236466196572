import * as React from 'react';

import { Layout } from 'antd';
import styled from 'styled-components';

const Wrapper = styled(Layout)`

`;

interface Props {
	padding?: string;
	maxWidth?: number;
}

interface State {

}

export default class Content extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		const { padding, maxWidth, ...rest } = this.props;
		return <Wrapper {...rest}>
			<Layout.Content className={'inner'} style={{
				margin  : '0 auto',
				width   : '100%',
				maxWidth: maxWidth || 1250,
				//	to disable horizontal scrolling when a Row with gutter
				//	is used inside this component make sure to set the left/right
				//	padding of this component to half of the value of gutter or bigger
				padding : this.props.padding || '0 20px',
			}}
			>
				{this.props.children}
			</Layout.Content>
		</Wrapper>;
	}
}
